import {WorkBook, WorkSheet} from 'xlsx';
import {IconName} from '../@theme/fortawesome/fontawesome-common-types';

export async function downloadTable(data: any[][], filename = 'report', format: 'XLSX' | 'CSV' = 'XLSX') {
    const XLSX = await import('xlsx');

    if (format == 'XLSX') {
        const ws: WorkSheet = XLSX.utils.aoa_to_sheet(data);
        const wb: WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${filename}.xlsx`);
    } else if (format == 'CSV') {
        const csv = data.reduce((acc, r) => acc.concat(`"${r.join('";"')}"\n`), '');
        const blob = new Blob([`\ufeff${csv}`], {type: 'text/csv;charset=utf-8;'});
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const navigator = window.navigator as any;
        if (navigator?.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
            return;
        }
        const isSafariBrowser = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
        if (isSafariBrowser) { //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', `${filename}.csv`);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
        //we need setTimeout here because of Safari
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
    }
}

export function objectToFormData(obj: {[keys: string]: string | {fileContent: Blob; fileName: string}}, formData = new FormData()) {
    Object.entries(obj).forEach(([key, value]) => {
        (typeof value === 'string') ?
            formData.append(key, value) :
            value ?
                formData.append(key, value.fileContent, value.fileName) :
                formData.append(key, undefined);
    });
    return formData;
}

/* File type icons */

export type FileTypeDisplayConfiguration = {icon: IconName | string; displayName: string};
type FileTypeDisplayConfigurationMap = {[keys: string]: FileTypeDisplayConfiguration};

const defaultFileTypeDisplayConfiguration: FileTypeDisplayConfiguration = {icon: 'file', displayName: 'File'};
const fileTypeDisplaysByMimeType: FileTypeDisplayConfigurationMap = {
    'text/html': {icon: 'html5', displayName: 'HTML'},
    'text/plain': {icon: 'file-alt', displayName: 'Text'},
    'text/csv': {icon: 'file-csv', displayName: 'CSV'},
    'application/xml': {icon: 'file-code', displayName: 'XML'},
    'application/cda+xml': {icon: 'file-code', displayName: 'CDA'},
    'application/pdf': {icon: 'file-pdf', displayName: 'PDF'},
    'application/zip': {icon: 'file-archive', displayName: 'ZIP'},
    'image/jpeg': {icon: 'file-image', displayName: 'JPEG'},
    'image/jpg': {icon: 'file-image', displayName: 'JPG'},
    'image/png': {icon: 'file-image', displayName: 'PNG'},
    'image/webm': {icon: 'file-image', displayName: 'WEBM'},
    'xhtml': {icon: 'file-code', displayName: 'XHTML'},
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {icon: 'file-excel', displayName: 'Excel'},
};

export function getFileTypeIcon(mimeType: string) {
    let fileTypeDisplayConfiguration: FileTypeDisplayConfiguration;

    const selectedConfig = fileTypeDisplaysByMimeType[mimeType];
    fileTypeDisplayConfiguration = {...selectedConfig};
    if (!selectedConfig) {
        fileTypeDisplayConfiguration = {...defaultFileTypeDisplayConfiguration};
        if (mimeType) {
            const mimeComponents = mimeType.split('/');
            const fileType = mimeComponents[1] || mimeComponents[0];
            fileTypeDisplayConfiguration.displayName = fileType.toUpperCase();
        }
    }
    return fileTypeDisplayConfiguration;
}
